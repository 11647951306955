/* Typography */
.h1 {
    @apply text-4xl font-extrabold leading-tight tracking-tight;
}

.h2 {
    @apply text-3xl font-extrabold leading-tight tracking-tight;
}

.h3 {
    @apply text-3xl font-bold leading-normal;
}

.h4 {
    @apply text-2xl font-bold leading-snug tracking-normal;
}

.logomain {
    @apply not-italic font-highlight uppercase tracking-tighter !important; 
}

.logosecondary {
    @apply not-italic lowercase tracking-wider !important;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

/* Buttons */
.btn,
.btn-sm {
    @apply font-medium inline-flex items-center justify-center border border-transparent rounded-sm leading-snug transition duration-150 ease-in-out;
}

.btn {
    @apply px-8 py-3;
}

.btn-sm {
    @apply px-4 py-2;
}

/* Forms */
input.form-input,
textarea.form-textarea,
select.form-multiselect,
select.form-select,
input.form-checkbox,
input.form-radio {
    @apply bg-transparent border border-gray-700 focus:border-gray-500;
}

input.form-input,
textarea.form-textarea,
select.form-multiselect,
select.form-select,
input.form-checkbox {
    @apply rounded-sm;
}

input.form-input,
textarea.form-textarea,
select.form-multiselect,
select.form-select {
    @apply py-3 px-4;
}

input.form-input,
textarea.form-textarea {
    @apply placeholder-gray-500;
}

select.form-select {
    @apply pr-10;
}

input.form-checkbox,
input.form-radio {
    @apply text-purple-600;
}