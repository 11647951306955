/* Futura buy https://www.myfonts.com/pack/638927 */
/* https://transfonter.org/ */
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback');
/* @import url('https://fonts.googleapis.com/css?family=Muli&display=fallback'); */
@import url("https://use.typekit.net/gag8lrv.css");

@import url('stylesheet.css');


@tailwind base;
@tailwind components;

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

@tailwind utilities;

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
    .rtl {
        direction: rtl;
    }
}

/* AOS styles */
@import 'aos/dist/aos.css';

[data-aos=fade-up] {
    transform: translate3d(0,10px,0)
}

[data-aos=fade-down] {
    transform: translate3d(0,-10px,0)
}

[data-aos=fade-right] {
    transform: translate3d(-10px,0,0)
}

[data-aos=fade-left] {
    transform: translate3d(10px,0,0)
}

[data-aos=fade-up-right] {
    transform: translate3d(-10px,10px,0)
}

[data-aos=fade-up-left] {
    transform: translate3d(10px,10px,0)
}

[data-aos=fade-down-right] {
    transform: translate3d(-10px,-10px,0)
}

[data-aos=fade-down-left] {
    transform: translate3d(10px,-10px,0)
}