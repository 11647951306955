  @font-face {
    font-family: 'Iconsinvestorxtemplate';
    src: url('fonts/iconsinvestorxtemplate.eot') format('embedded-opentype'), url('fonts/iconsinvestorxtemplate.woff') format('woff'), url('fonts/iconsinvestorxtemplate.ttf') format('truetype'), url('fonts/iconsinvestorxtemplate.svg') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Muli';
    src: url('fonts/Muli-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }